@import '../../resources/main.scss';

.add{
    &__title{
        position: relative;
        bottom:20px;
    }
    &__container{
        display: flex;
        flex-direction: row;
       padding:40px 10%;
    }
    &__right-section{
        width:60%;
        margin-top: 40px;
    }
    &__left-section{
        width:40%;
    }
    &__form{
        display: flex;
        flex-direction: column;
        margin:20px 0 20px;
    }
    &__label{
        padding-top: 15px;
    }
    &__input{
        margin:10px 0 25px;
        height:44px;
        border-radius: 8px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        border:none;
        border-radius: 5px;
        padding-left: 5px;
        font-family: 'Domine', serif;
        font-size: 14px;
        width: 100%;
    }
    &__input:focus{
        outline:none;
    }
    &__textarea{
        height:auto;
    }
    &__input::placeholder{
        font-family: 'Domine', serif;
        font-size: 14px;
        line-height: 1.47;
        color: #444444;
        font-weight: bolder;
    }
    &__button{
        border:1px solid #000;
        cursor: pointer;
        width:30%;
        height:48px;
        border-radius: 5px;
        background:#fff;
        margin-top: 10px;
    }
    &__button-writing{
        color:#000;
        font-size: 14px;
    }
    &__left-section{
        display: flex;
        flex-direction: column;
        margin-right: 60px;
    }
    &__fixed{
        display: flex;
        flex-direction: column;
        position:fixed;
        width:40%;
        margin-top: 20px;
    }
    &__actions{
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -0.63px;
        color: #010101;
       border-bottom: 2px solid #f2f2f2;
        padding-bottom: 10px;
        width:50%;
    }
    &__add-button{
        width:40%;
        margin:40px 0 0;
        background-color: #fff;
        border:none;
        border-radius: 5px;
        padding:15px 0;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }
    &__indication{
        color:#000;
        font-size: 16px;
        font-weight: bold;
        
    }
    &__input-box{
        width:100%;
        padding: 10px 0;

        input, textarea{
            width:100%;
        }

        textarea {
          height: 100%;
        }
    }
    &__input-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &__remove-input{
        width:12px;
        height:12px;
        position: relative;
        right:20px;
        bottom:12px;
        cursor: pointer;
    }

    &__references {
      padding: 20px 0 40px;
    }

    &__references-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__reference {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }

    &__reference--input {
      width: 100%;

      input {
        width: 100%;
      }

      .add__remove-input {
        right: 0;
        top: 0;
        left: 100%;
      }
    }

    &__dropzone-area {
      width: 100%;
    }
    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        margin-top: 25px;
        margin-left: 30px;
        height: 24px;
      }
      
      /* Hide default HTML checkbox */
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      
      /* The slider */
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      .slider:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      input:checked + .slider {
        background-color: green;
      }
      
      input:focus + .slider {
        box-shadow: 0 0 1px green;
      }
      
      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
      
      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }
      
      .slider.round:before {
        border-radius: 50%;
      }
      &__active-article{
          display: flex;
          flex-direction: row;
          margin:10px 0;
          h4{
              position: relative;
              top:15px;
          }
      }
}
