
.articles{
    &__header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-shadow: 5px 13px 13px -8px rgba(0,0,0,0.19);
        padding:40px 10%;
    }
    &__header-title{
        font-size: 24px;
        padding-top: 10px;
    }
    &__add-article-button{
        border-radius: 12px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        height:48px;
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width:180px;
    }
    &__container{
        padding:40px 10%;
    }
    &__header-actions{
        display: flex;
        flex-direction: row;
    }
    &__menu-item{
        font-size: 16px;
        padding:0 30px;
        position: relative;
        top:15px;
    }
    &__action-article-box{
        cursor: pointer;
    }
    &__header-icon{
        width:12px;
        margin:0 10px;
    }
    &__action-logout{
        flex-direction: row-reverse;
        margin-left: 40px;
    }
    &__title-page{
        font-size: 26px;
        font-weight: bold;
        padding:20px 0 0;
    }
    &__wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin:40px 0;
    }
    &__card{
        width:28%;
        border-radius: 14px;
        background-color: #ffff;
        margin:20px 0;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }
    &__card-img{
        width:100%;
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        height:250px;
        object-fit: cover;
    }
    &__card-info{
        padding:20px;
        h4, h5, p{
            text-align: left;
        }
    }
    &__preview{
        color:#1a1a1a;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding:5px 0;
        font-size: 16px;
        line-height: 1.47;
        color: #444444;
    }
    &__title{
        font-size: 18px;
        font-weight: bold;
        letter-spacing: -0.5px;
        color: #010101;
    }
    &__date{
        color:#00001a;
        padding:5px 0;
    }
    &__see-more{
        color: #2386b2;
        font-weight: bolder;
        font-size: 14px;
        text-decoration: none;
        
    }
}